import React, { Component } from 'react';
import axios from 'axios';
import {Container, Table} from 'react-bootstrap'
import './org-limits.css';

export default class OrgLimits extends Component {

    constructor(props) {
        super(props);
        this.state = {
            limitObj: {}
        };
    }

    componentDidMount() {
        var self = this;
        axios.get('/sfroma/orgLimits', { withCredentials: true })
            .then(function (res) {
                if (res.status === 200) {
                    self.setState({
                        limitObj: res.data
                    });
                } else {
                    //fail
                }
            });
    }

    render() {
        return ( 
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Used</th>
                            <th>Remaining</th>
                            <th>Maximum</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(this.state.limitObj).map((entry) => {
                            var used = entry[1].Max - entry[1].Remaining;
                            var usedPerc = entry[1].Max==0 ? 0 : used / entry[1].Max*100;
                            if(usedPerc>70){
                                return (
                                    <tr style={{ "background-color": "#ff9696" }}>
                                        <td key={entry[0]}>{entry[0]}</td>
                                        <td key={used}>{used} ({usedPerc.toFixed(1)}%)</td>
                                        <td key={entry[1].Remaining}>{entry[1].Remaining}</td>
                                        <td key={entry[1].Max}>{entry[1].Max}</td>
                                    </tr>);
                            } else{
                                return (
                                    <tr>
                                        <td key={entry[0]}>{entry[0]}</td>
                                        <td key={used}>{used} ({usedPerc.toFixed(1)}%)</td>
                                        <td key={entry[1].Remaining}>{entry[1].Remaining}</td>
                                        <td key={entry[1].Max}>{entry[1].Max}</td>
                                    </tr>);
                            }
                        })
                        }
                    </tbody>
                </Table>
        );
    }
}
