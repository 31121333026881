import React, { Component } from 'react';

import './user-login.css';

export default class UserLogin extends Component {
    constructor(props) {
        super(props);

        this.onChangeEnvironment = this.onChangeEnvironment.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            login_environment:'Production',
            isLoggedIn : false
        }
    }

    onChangeEnvironment(e) {
        this.setState({
            login_environment: e.target.value
        });
    }

    onSubmit(e) {
        e.preventDefault();
        window.location = window.location.origin.replace('3000','8080')+'/sfroma/login?login_environment=' + this.state.login_environment;
    }

    render() {
        return (
            <div style={{ marginTop: 10 }}>
                <h2>Login to salesforce</h2>
                <form onSubmit={this.onSubmit} >
                    <div className="form-group custom-padding">
                        <div className="form-check form-check-inline">
                            <input className="form-check-input"
                                type="radio"
                                name="priorityOptions"
                                id="prodEnv"
                                value="Production"
                                checked={this.state.login_environment === 'Production'}
                                onChange={this.onChangeEnvironment}/>
                            <label className="form-check-label custom-font">Production</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input"
                                type="radio"
                                name="priorityOptions"
                                id="sandboxEnv"
                                value="Sandbox"
                                checked={this.state.login_environment === 'Sandbox'}
                                onChange={this.onChangeEnvironment}/>
                            <label className="form-check-label custom-font">Sandbox</label>
                        </div>
                    </div>
                    <div className="form-group custom-padding">
                        <input type="submit" value="Login" className="btn btn-primary custom-font" />
                    </div>
                </form>
            </div>
            );
        }
}