import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import UserContext from './components/user-context.component';
import UserLogin from './components/user-login.component';
import SfDataModel from './components/sf-data-model.component';
import ExportDataModel from './components/export-data-model.component';
import HomePage from './components/homepage.component';
import NavBar from './components/navbar.component';

import axios from 'axios';
import OrgLimits from './components/org-limits';

class App extends Component {
  

  constructor() {
    super();

    this.updateUser = (user, isFetching) => {
      this.setState({ user: user, isFetching:isFetching });
    };

    this.state = {
      user: null,
      isFetching: true,
      updateUser: this.updateUser
    };
  }
  
  componentWillMount() {
    var self = this;
    // query to get userId if null alors login page
    axios.get('/sfroma/userInfo', { withCredentials: true })
      .then(function (res) {
        if (res.status === 200 && res.data.LoggedIn) {
          
          self.updateUser(res.data.data,false);       
        } else {
          self.updateUser(null, false);
        }
      });
  }

  render() {return (
      <UserContext.Provider value={this.state}>
        <Router>
          <div className="container">
            
            <NavBar/>
            <Route path="/" exact component={HomePage} /> 
            <Route path="/login" exact component={UserLogin} />
            <Route path="/sfdatamodel" component={SfDataModel} />
            <Route path="/exportdatamodel" component={ExportDataModel} />
            <Route path="/orglimits" component={OrgLimits} />
          </div>
        </Router>
        
      </UserContext.Provider>
    );
  }
}

export default App;
