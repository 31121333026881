import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { Container, Row, Button } from 'react-bootstrap'
import {
    mxGraph, mxRubberband, mxKeyHandler, mxClient, mxUtils, mxEvent, mxCircleLayout, mxMedianHybridCrossingReduction, mxConstants } from 'mxgraph-js';

import './sf-data-model.css';

export default class SfDataModel extends Component {

    constructor(props) {
        super(props);

        this.getSfModel = this.getSfModel.bind(this);

    }

    getSfModel(e) {
        e.preventDefault();

        var self = this;
        axios.get('/sfroma/getSfModel', { withCredentials: true })
            .then(function (res) {
                if (res.status === 200) {

                    //Start building graph
                    var container = ReactDOM.findDOMNode(self.refs.divGraph);
                    // console.log(container);

                    // Checks if the browser is supported
                    if (!mxClient.isBrowserSupported()) {
                        // Displays an error message if the browser is not supported.
                        mxUtils.error('Browser is not supported!', 200, false);
                    } else {
                        // Disables the built-in context menu
                        mxEvent.disableContextMenu(container);

                        // Creates the graph inside the given container
                        var graph = new mxGraph(container);

                        // Enables rubberband selection
                        new mxRubberband(graph);

                        // Gets the default parent for inserting new cells. This is normally the first
                        // child of the root (ie. layer 0).
                        var parent = graph.getDefaultParent();

                        // Enables tooltips, new connections and panning
                        graph.setPanning(false);
                        graph.setTooltips(true);
                        graph.setConnectable(false);
                        graph.setEnabled(true);
                        graph.setEdgeLabelsMovable(false);
                        graph.setVertexLabelsMovable(false);
                        graph.setGridEnabled(true);
                        graph.setAllowDanglingEdges(false);
                        graph.setAutoSizeCells(true);
                        graph.autoSizeCellsOnAdd = true;

                        graph.getModel().beginUpdate();
                        try {
                            //mxGrapg component
                            var doc = mxUtils.createXmlDocument();
                            var node = doc.createElement('Node');
                            node.setAttribute('ComponentID', '[P01]');
                            var vertexStyle = graph.getStylesheet().getDefaultVertexStyle();
                            vertexStyle[mxConstants.STYLE_ROUNDED] = true;
                        //End Building graph

                        var objSet = new Set();
                        var objMap = JSON.parse(res.data);
                        var fromObj = [];
                        Object.keys(objMap).forEach(function (elmt) {
                            objSet.add(elmt);
                            fromObj.push(elmt);
                        });
                        Object.values(objMap).forEach(function (elmtL) {
                            elmtL.forEach(function (elmt) {
                                objSet.add(elmt);
                            });
                        });

                        var nodeMap = new Map();
                        objSet.forEach(function (node) {

                            if (!nodeMap.has(node)) {
                                nodeMap.set(node, graph.insertVertex(parent, null, node, 0, 0, 80, 30));
                            }
                        });
                       
                        fromObj.forEach(function (objChild) {
                            objMap[objChild].forEach(function (objParent) {
                                graph.insertEdge(parent, null, '', nodeMap.get(objParent), nodeMap.get(objChild));
                            });
                        });
                            
                            var layoutMgr = new mxCircleLayout(graph);
                            layoutMgr.execute(parent);
                            var layout = new mxMedianHybridCrossingReduction(graph);
                            layout.execute(parent);
                    } finally {
                        // Updates the display
                        graph.getModel().endUpdate();
                    }

                    // Enables rubberband (marquee) selection and a handler for basic keystrokes
                    var rubberband = new mxRubberband(graph);
                    var keyHandler = new mxKeyHandler(graph);
                }
                } else {
                    //fail
                }
            });
    }

    render() {
        return (
            <Container>
                <Row sm={2}>
                    <Button onClick={this.getSfModel} className="custom-font">Generate Schema</Button>
                </Row>
                <br></br>
                    <Row sm={8} className="row-padding">
                        <div className="graph-container" ref="divGraph" id="divGraph" />
                    </Row>
            </Container>
        );
    }
}