import React, { Component } from 'react';
import axios from 'axios';
import {Container, Row, Col, Table, Button, Form, Accordion, Card, Spinner} from 'react-bootstrap'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import './export-data-model.css';

export default class ExportDataModel extends Component {

    constructor(props) {
        super(props);

        this.exportDataModel = this.exportDataModel.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
        this.onMultiSelectChange = this.onMultiSelectChange.bind(this);
        this.state = {  objectList:[],
                        objectNameL: [],
                        objectFieldsL:[],
                        selectObjects:'',
                        loading:false};
        this.columnHeaders = ['Label', 'Name', 'Type', 'Length', 'Custom Field', 'Default Value', 'Default Value Formula', 'Calculated Formula', 'Compound Field Name', 'Encrypted', 'Restricted Picklist', 'Dependent Picklist', 'Reference To', 'Relationship Name'];

    }

    componentDidMount() {
        var self = this;
        axios.get('/sfroma/listSobjects', { withCredentials: true })
            .then(function (res) {
                if (res.status === 200) {
                    self.setState({
                        objectList: res.data
                    });
                } else {
                    //fail
                }
            });
    }

    exportDataModel(e) {
        e.preventDefault();
        var self = this;
        self.setState({loading:true});
        axios.get('/sfroma/exportDataModel?sObjects='+self.state.selectObjects, { withCredentials: true })
            .then(function (res) {
                if (res.status === 200) {
                    var objMap = JSON.parse(res.data);
                    var objNameL = Object.keys(objMap);
                    var objFieldL = [];
                    objNameL.forEach(function(objName){
                        var objField = [];
                        objMap[objName].forEach(function(field){
                            var newField = { label: field.label, 
                                            name: field.name, 
                                            type: (field.autoNumber === true ? 'Autonumber' : field.calculated === true ? 'Formula(' : '') + field.type + (field.calculated === true ? ')' : '') + (field.externalId === true ? ' external id' : '') + (field.unique === true ? ' unique' : '') + (field.caseSensitive === true ? ' case sensitive' : ''),
                                            length: field.type === 'int' ? field.digits : field.type === 'double' ? field.precision + ',' + field.scale : field.length,
                                            custom: field.custom === true ? 'true' : 'false',
                                            defaultValue: field.defaultValue,
                                            defaultValueFormula: field.defaultValueFormula,
                                            calculatedFormula: field.calculatedFormula,
                                            compoundFieldName: field.compoundFieldName,
                                            encrypted: field.encrypted === true ? 'true' : 'false',
                                            restrictedPicklist: field.restrictedPicklist === true ? 'true' : 'false',
                                            dependentPicklist: field.dependentPicklist === true ? 'true' : 'false',
                                            idLookup: field.referenceTo,
                                            relationshipName: field.relationshipName
                            };
                            objField.push(newField);
                        })
                        objFieldL.push(objField);
                    });
                    self.setState({
                        objectNameL: objNameL,
                        objectFieldsL: objFieldL,
                        loading:false
                    });
                } else {
                    //fail
                }
            });
    }

    exportToCsv(e) {
        e.preventDefault();
        var self = this;
        self.setState({ loading: true });
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        var wb = { Sheets: {},SheetNames:[]};
        self.state.objectNameL.map((objName,i)=>{
            wb.SheetNames.push(objName.substring(0, 30));
            wb.Sheets[objName.substring(0, 30)]= XLSX.utils.json_to_sheet(self.state.objectFieldsL[i]);
        });
        //const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'exportDataModel' + fileExtension);
        self.setState({ loading: false });
    }

    onMultiSelectChange(e){
        var self=this;
        var selVal = Array.from(e.target.selectedOptions, option => option.value).toString();
        self.setState({
            selectObjects: selVal
        });
    }

    render() {
        var {loading} = this.state;
        return (
            <Container>
            
                {loading && <Spinner animation="border" role="status" className="spin-center">
                    <span className="sr-only">Loading...</span>
                </Spinner>}
                <Row>
                    <Col sm={4} className="text-center row-padding">
                        <Button onClick={this.exportDataModel} className='btn-size custom-font'>Display Object Details</Button>
                        <br></br>
                        <br></br>
                        {this.state.objectNameL.length > 0 &&
                            <div className="position-relative">
                            <Button variant="warning" onClick={this.exportToCsv} className='btn-size custom-font'>Export to Excel</Button>
                            </div >
                        }
                    </Col>
                    <Col sm={8} className="row-padding">
                        <Form>
                            <Form.Group controlId="sObjectList">
                                <Form.Label className="custom-font">Select the sObjects you want to export:</Form.Label>
                                <Form.Control as="select" htmlSize={10} multiple onChange={this.onMultiSelectChange}>
                                    {this.state.objectList.map((obj, objId)=>{
                                        return (<option key={obj + objId} className="custom-font-select">{obj}</option>);
                                    })}
                                </Form.Control>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                <br></br>
                <br></br>
                <Accordion defaultActiveKey="0" className="row-padding">
                    {this.state.objectNameL.map((objectName, index)=>{
                        return (
                    <React.Fragment>   
                    <Card >
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey={objectName + index}>
                                {objectName}
                            </Accordion.Toggle>
                        </Card.Header> 
                                    <Accordion.Collapse eventKey={objectName+index}>
                            <Card.Body>
                                <Table responsive >
                                    <thead>
                                        <tr>
                                            {this.columnHeaders.map((header, headId) => {
                                                return <th scope="col" key={headId+index}>{header}</th>;
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>

                                                {Object.values(this.state.objectFieldsL[index]).map((field, i) => {
                                                    return (<tr key={i}>
                                                        <td key={field.label}>{field.label}</td>
                                                        <td key={field.name}>{field.name}</td>
                                                        <td key={field.type}>{field.type}</td>
                                                        <td key={field.length}>{field.length}</td>
                                                        <td key={field.custom}>{field.custom}</td>
                                                        <td key={field.defaultValue}>{field.defaultValue}</td>
                                                        <td key={field.defaultValueFormula}>{field.defaultValueFormula}</td>
                                                        <td key={field.calculatedFormula}>{field.calculatedFormula}</td>
                                                        <td key={field.compoundFieldName}>{field.compoundFieldName}</td>
                                                        <td key={field.encrypted}>{field.encrypted}</td>
                                                        <td key={field.restrictedPicklist}>{field.restrictedPicklist}</td>
                                                        <td key={field.dependentPicklist}>{field.dependentPicklist}</td>
                                                        <td key={field.idLookup}>{field.referenceTo}</td>
                                                        <td key={field.relationshipName}>{field.relationshipName}</td>
                                                    </tr>
                                                    );
                                                })
                                                }
                                    </tbody>
                                        
                                </Table>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    </React.Fragment>
                    );})}
                    </Accordion>
            </Container>
        );
    }
}
