import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import UserContext from './user-context.component';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'

import './navbar.css';

export default class NavBar extends Component {

    render() {
        const showMenu = (user, isFetching) => {
            if (user !== null && !isFetching){
                return(
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto custom-font">
                            <Nav.Link href="/">Home</Nav.Link>
                            {/*<Nav.Link href="https://mylittlebluecloud.com">Blog</Nav.Link>*/}
                        </Nav>
                        <Nav>
                            {/*<Nav.Link href="/sfroma/logout">Logout</Nav.Link>*/}
                            <NavDropdown title={user.display_name} id="basic-nav-dropdown">
                                <NavDropdown.Item disabled>Username: {user.username}</NavDropdown.Item>
                                <NavDropdown.Item disabled>Org Id: {user.organization_id}</NavDropdown.Item>
                                <NavDropdown.Item disabled>Instance: {user.urls.custom_domain}</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/sfroma/logout">Logout</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                );
            } else if (!isFetching){
                return(
                    <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto custom-font">
                            {/*<Nav.Link href="https://mylittlebluecloud.com">Blog</Nav.Link>*/}
                    </Nav>
                </Navbar.Collapse>
                );
            }
        }
        return (
            <UserContext.Consumer>
                
                {({user, isFetching}) =>(
                
                    <Navbar bg="light" expand="lg" >
                        {user === null && !isFetching && <Redirect to="/login" />} 
                        <Navbar.Brand href="/">My Little Blue Cloud</Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        {showMenu(user, isFetching)}
                    </Navbar> 
                )}
            </UserContext.Consumer>
        );
    }
}