import React, { Component } from 'react';
import UserContext from './user-context.component';
import { Container, Row, Col } from 'react-bootstrap'

import './homepage.css';

export default class HomePage extends Component {

    constructor(props) {
        super(props);

        
    }

    render() {
        const redirectToLogin = (user, isFetching) => {
            if (user && !isFetching) {
                return (
                <Container>
                    <Row sm={2}>
                        <Col sm={6} className="text-center row-padding">
                            <a href="/sfdatamodel" className="btn btn-outline-primary btn-feature"><span className="glyphicon glyphicon-th icon-size"></span><br></br>Data Model Schema</a>
                        </Col>
                        <Col sm={6} className="text-center row-padding">
                            <a href="/exportdatamodel" className="btn btn-outline-primary btn-feature"><span className="glyphicon glyphicon-list-alt icon-size"></span><br></br>Export Data Dictionnary</a>
                        </Col>
                    </Row>
                    <Row sm={2} className="row-padding">
                        <Col sm={6} className="text-center">
                            <a href="/orglimits" className="btn btn-outline-primary btn-feature"><span className="glyphicon glyphicon-info-sign icon-size"></span><br></br>Org Limits</a>
                        </Col>
                    </Row>
                   {/* <Row sm={2} className="row-padding">
                        <Col sm={6} className="text-center">
                            <a href="/querytool" className="btn btn-outline-primary btn-feature"><span className="glyphicon glyphicon-search icon-size"></span><br></br>Query Tool</a>
                        </Col>
                        <Col sm={6} className="text-center">
                                <a href="/testanalysis" className="btn btn-outline-primary btn-feature"><span className="glyphicon glyphicon-stats icon-size"></span><br></br>Test Class Analysis</a>
                        </Col>
                    </Row>
                    <Row sm={2} className="row-padding">
                        <Col sm={6} className="text-center">
                                <a href="/dependency" className="btn btn-outline-primary btn-feature"><span className="glyphicon glyphicon-random icon-size"></span><br></br>Apex Dependencies</a>
                        </Col>
                    </Row>*/}
                </Container>
                );
            }
        }
        return (
            <UserContext.Consumer>
                {({ user, isFetching }) => (
                    redirectToLogin(user, isFetching)
                )}
            </UserContext.Consumer>
        );
    }
}